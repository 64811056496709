import xhr from "./fetch";
import { getAppVersionHeaders } from "./fetch/headers";
import { ApiOptions } from "./types";

const client = ({
  apiUrl,
  appVersion,
}: {
  apiUrl: string;
  appVersion?: string;
}) => {
  const appVersionHeaders = getAppVersionHeaders("booking-form", appVersion);

  const defaultHeaders = {
    accept: "application/vnd.fcdexpress.v1+json",
    ...appVersionHeaders,
  };

  return ({ url, headers, ...config }: ApiOptions) => {
    return xhr({
      url: /^https?:\/\//.test(url) ? url : `${apiUrl}/${url}`,
      headers: {
        ...defaultHeaders,
        ...headers,
      },
      ...config,
    });
  };
};

export default client;
