import React from "react";
import { t } from "ttag";
import { Row, Col, Image, Button } from "antd";
// import { useLocation } from "react-router-dom";

// import de from "../../assets/images/confirmation/de.png";
// import fr from "../../assets/images/confirmation/fr.png";
// import en from "../../assets/images/confirmation/en.png";

import "./styles.less";

const Confirmation = (): JSX.Element => {
  // const { pathname } = useLocation();
  // const localeCode = pathname.substring(1, 3).toLowerCase();

  const sendToBack = (): void => {
    window.location.href = `https://mikrobus.bg/`;
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="confirmation">
      <Row>
        <Col sm={24}>
          <div className="confirmation-text">
            <h1>{t`Thank you for request! We will contact you as soon as possible!`}</h1>
            <Button onClick={sendToBack}>{t`Back`}</Button>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default Confirmation;
