interface Country {
  abbr: string;
  code: string;
  icon: string;
  name: string;
  suggested?: boolean;
}

export const allCountries: Array<Country> = [
  { icon: "🇦🇹", name: "Austria", abbr: "AT", code: "43" },
  { icon: "🇧🇪", name: "Belgium", abbr: "BE", code: "32" },
  { icon: "🇧🇬", name: "Bulgaria", abbr: "BG", code: "359" },
  { icon: "🇭🇷", name: "Croatia", abbr: "HR", code: "385" },
  { icon: "🇨🇿", name: "Czech Republic", abbr: "CZ", code: "420" },
  { icon: "🇩🇰", name: "Denmark", abbr: "DK", code: "45" },
  { icon: "🇪🇪", name: "Estonia", abbr: "EE", code: "372" },
  { icon: "🇫🇮", name: "Finland", abbr: "FI", code: "358" },
  { icon: "🇫🇷", name: "France", abbr: "FR", code: "33", suggested: true },
  { icon: "🇩🇪", name: "Germany", abbr: "DE", code: "49", suggested: true },
  { icon: "🇬🇷", name: "Greece", abbr: "GR", code: "30" },
  { icon: "🇭🇺", name: "Hungary", abbr: "HU", code: "36" },
  { icon: "🇪🇸", name: "Spain", abbr: "ES", code: "34" },
  { icon: "🇸🇪", name: "Sweden", abbr: "SE", code: "46" },
  { icon: "🇨🇭", name: "Switzerland", abbr: "CH", code: "41" },
  { icon: "🇮🇹", name: "Italy", abbr: "IT", code: "39" },
  { icon: "🇱🇻", name: "Latvia", abbr: "LV", code: "371" },
  { icon: "🇱🇹", name: "Lithuania", abbr: "LT", code: "370" },
  { icon: "🇱🇺", name: "Luxembourg", abbr: "LU", code: "352" },
  { icon: "🇳🇱", name: "Netherlands", abbr: "NL", code: "31" },
  { icon: "🇵🇱", name: "Poland", abbr: "PL", code: "48" },
  { icon: "🇵🇹", name: "Portugal", abbr: "PT", code: "351" },
  { icon: "🇷🇴", name: "Romania", abbr: "RO", code: "40" },
  { icon: "🇸🇰", name: "Slovakia", abbr: "SK", code: "421" },
  { icon: "🇸🇮", name: "Slovenia", abbr: "SI", code: "386" },
];

export const groupedCountries = [
  {
    label: "Suggested",
    options: allCountries.filter((c) => c.suggested),
  },
  {
    label: "All Countries",
    options: allCountries.filter((c) => !c.suggested),
  },
];
