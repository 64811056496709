import React from "react";
import { useLocation } from "react-router-dom";
import { Layout as AntdLayout, ConfigProvider } from "antd";
import classNames from "classnames";
import { useLocale, addLocale } from "ttag";

import bgBG from "antd/es/locale/bg_BG";

import { Provider as ApiProvider } from "../../api";

import { Header } from "../header";
import { Footer } from "../footer";
import { Guides } from "../guides";

import "./style.less";

export interface LayoutProps {
  children?: React.ReactNode;
  className?: string;
  showHeader?: boolean;
  showFooter?: boolean;
}

const { Content } = AntdLayout;

function Layout(props: LayoutProps): React.ReactElement | null {
  const { children, className, showHeader = false, showFooter = false } = props;

  return (
    <>
      <AntdLayout className={classNames(className)}>
        <Guides />
        {showHeader && <Header />}
        <Content role="main">{children}</Content>
        {showFooter && <Footer />}
      </AntdLayout>
    </>
  );
}

function LayoutWrapper(props: LayoutProps): React.ReactElement {
  const translationObj = require(`../../locale/messages/bg.json`);

  addLocale("bg", translationObj);
  useLocale("bg");

  return (
    <ApiProvider apiUrl="https://api.fcd-express.com/api">
      <ConfigProvider componentSize="large" prefixCls="fcd" locale={bgBG}>
        <Layout {...props} />
      </ConfigProvider>
    </ApiProvider>
  );
}

export default React.memo(LayoutWrapper);
