import Icon, { IconProps } from "../../icon";

const PersonalInformationIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg
        width="37"
        height="34"
        viewBox="0 0 37 34"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <clipPath id="UserWithCheckmarkIconClipPath--a">
            <path
              d="M16 5c8.837 0 16 7.163 16 16 0 5.087-2.374 9.62-6.074 12.55-.888-3.31-4.994-5.813-9.926-5.813s-9.038 2.502-9.927 5.812A15.972 15.972 0 010 21C0 12.163 7.163 5 16 5zm0 10a5 5 0 100 10 5 5 0 000-10z"
              style={{ fill: "var(--fcd-icon-background)" }}
            ></path>
          </clipPath>
        </defs>
        <path
          d="M16 5c8.837 0 16 7.163 16 16 0 5.087-2.374 9.62-6.074 12.55-.888-3.31-4.994-5.813-9.926-5.813s-9.038 2.502-9.927 5.812A15.972 15.972 0 010 21C0 12.163 7.163 5 16 5zm0 10a5 5 0 100 10 5 5 0 000-10z"
          style={{ fill: "var(--fcd-icon-background)" }}
        ></path>
        <path
          d="M28 0a9 9 0 110 18 9 9 0 010-18zm3.94 4.73L26 10.78 24.43 9a.83.83 0 00-1.3 1L25 13.2a1 1 0 001.7 0c.3-.41 6-7.57 6-7.57.75-.8-.16-1.51-.76-.9z"
          style={{ fill: "var(--fcd-icon-foreground)" }}
        ></path>
        <g clipPath="url(#UserWithCheckmarkIconClipPath--a)">
          <path
            d="M28 0a9 9 0 110 18 9 9 0 010-18zm3.94 4.73L26 10.78 24.43 9a.83.83 0 00-1.3 1L25 13.2a1 1 0 001.7 0c.3-.41 6-7.57 6-7.57.75-.8-.16-1.51-.76-.9z"
            style={{ fill: "var(--fcd-icon-intersection)" }}
          ></path>
        </g>
      </svg>
    </Icon>
  );
};

export default PersonalInformationIcon;
