import Icon, { IconProps } from "../../icon";

const PriceInformationIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg
        width="96"
        height="96"
        preserveAspectRatio="xMinYMin"
        viewBox="0 0 96 96"
      >
        <defs>
          <path
            id="pricingIconTag"
            d="M41.4 30.38l8.4 8.27A4 4 0 0 1 51 41.5V62a3 3 0 0 1-3 3H32a3 3 0 0 1-3-3V41.5a4 4 0 0 1 1.2-2.85l8.4-8.27a2 2 0 0 1 2.8 0zM40 36a3 3 0 1 0 0 6 3 3 0 0 0 0-6z"
          ></path>
          <clipPath id="pricingClipPath">
            <use
              href="#pricingIconTag"
              style={{
                transformOrigin: "40px 39px",
                transform: "rotate(45deg)",
              }}
            ></use>
          </clipPath>
        </defs>

        <use
          href="#pricingIconTag"
          fill="var(--fcd-icon-background)"
          style={{ transformOrigin: "40px 39px", transform: "rotate(45deg)" }}
        ></use>
        <use
          href="#pricingIconTag"
          fill="var(--fcd-icon-foreground)"
          style={{ transformOrigin: "40px 39px" }}
        ></use>
        <g clipPath="url(#pricingClipPath)">
          <use
            href="#pricingIconTag"
            fill="var(--fcd-icon-intersection)"
            style={{ transformOrigin: "40px 39px" }}
          ></use>
        </g>
      </svg>
    </Icon>
  );
};

export default PriceInformationIcon;
