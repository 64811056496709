import React from "react";
import { t } from "ttag";
import { Form, Input, Row, Col } from "antd";

import { PhoneCodeSelect } from "../../select";

const PersonalInformation = (): React.ReactElement => {
  return (
    <>
      <Row gutter={24}>
        <Col xs={24} md={12}>
          <Form.Item
            name="names"
            label={t`Names`}
            rules={[
              {
                required: true,
                message: t`This field is required.`,
              },
            ]}
          >
            <Input type="" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name="company"
            label={t`Company`}
            rules={[
              {
                required: true,
                message: t`This field is required.`,
              },
            ]}
          >
            <Input type="" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12}>
          <Form.Item
            name="email"
            label={t`E-mail`}
            rules={[
              {
                type: "email",
                message: t`The input is not valid E-mail!`,
              },
              {
                required: true,
                message: t`Please input your E-mail!`,
              },
            ]}
          >
            <Input type="" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name="phone"
            label={t`Phone number`}
            rules={[
              {
                required: true,
                message: t`Please input your phone number.`,
              },
            ]}
          >
            <Input
              type=""
              addonBefore={
                <Form.Item name="phonePrefix" noStyle>
                  <PhoneCodeSelect style={{ width: 90 }} />
                </Form.Item>
              }
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default PersonalInformation;
