import React from "react";
import { Layout as AntdLayout, Menu } from "antd";
import classNames from "classnames";

import { MENU_ITEMS } from "../../constants";
import { MainLogo } from "../logo";

import "./styles.less";

const { Header: AntdHeader } = AntdLayout;

interface HeaderProps {
  className?: string;
}

const Header = ({ className }: HeaderProps): React.ReactElement => {
  return (
    <AntdHeader className={classNames(className, "ant-row")}>
      <h1 className="ant-col ant-col-xs-24 ant-col-md-4 fcd-logo-wrapper">
        <MainLogo />
      </h1>
    </AntdHeader>
  );
};

export default Header;
