import { createContext, ReactNode, useRef } from "react";
import apiClient from "./client";

import { Api, ApiOptions } from "./types";

export const Context = createContext<Api | undefined>(undefined);

interface ProviderProps {
  apiUrl: string;
  appVersion?: string;
  children: ReactNode;
}

export const Provider = ({ apiUrl, appVersion, children }: ProviderProps) => {
  const apiRef = useRef<Api>();

  const client = apiClient({ apiUrl, appVersion });

  apiRef.current = ({ output = "json", ...rest }: ApiOptions) => {
    return client({ ...rest })
      .then((response) => {
        if (output === "stream") {
          return response.body;
        }

        if (output === "raw") {
          return response;
        }

        return response[output]();
      })
      .catch((e) => {
        throw e;
      });
  };

  return <Context.Provider value={apiRef.current}>{children}</Context.Provider>;
};
