import { DEFAULT_TIMEOUT } from "../../constants";
import { checkResponseStatus, createEndpoint, serializeData } from "./utils";
import { createOfflineError, createTimeoutError } from "./errors";
import { FetchWrapperOptions, FetchOptions } from "./types";

const myFetch = async ({ url, params, signal, ...rest }: FetchOptions) => {
  let isTimeout = false;
  const abortController = new AbortController();

  const fetchConfig: RequestInit = {
    //mode: "cors",
    //credentials: "include",
    redirect: "follow",
    signal: abortController.signal,
    ...rest,
  };

  const endpoint = createEndpoint(url, params);

  const timeoutHandler = setTimeout(() => {
    isTimeout = true;
    abortController.abort();
  }, DEFAULT_TIMEOUT);

  if (signal) {
    signal.addEventListener("abort", (): void => {
      abortController.abort();
      clearTimeout(timeoutHandler);
    });
  }

  try {
    const response = await fetch(endpoint, fetchConfig);
    clearTimeout(timeoutHandler);
    return checkResponseStatus(response, fetchConfig);
  } catch (e: any) {
    if (isTimeout) {
      throw createTimeoutError(fetchConfig);
    }

    if (e.name === "AbortError") {
      throw e;
    }

    throw createOfflineError(fetchConfig);
  }
};

const myFetchWrapper = ({
  data,
  headers,
  input = "json",
  ...config
}: FetchWrapperOptions) => {
  const { headers: dataHeaders, body } = serializeData(data, input);

  return myFetch({
    ...config,
    headers: {
      ...headers,
      ...dataHeaders,
    },
    body,
  });
};

export default myFetchWrapper;
