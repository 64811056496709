import React from "react";
import {
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Button,
  Space,
  Skeleton,
} from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { t } from "ttag";

import { Vehicle } from "../../../types";

import "./styles.less";

interface ShipmentInformationProps {
  id: number;
  currentVehicle?: Vehicle;
  onClickAddHandler: () => void;
  onClickRemoveHandler: (id: number) => void;
}

const ShipmentInformation = ({
  id,
  currentVehicle,
  onClickAddHandler,
  onClickRemoveHandler,
}: ShipmentInformationProps): JSX.Element | null => {
  const form = Form.useFormInstance();

  const {
    weight = 0,
    length = 0,
    width = 0,
    height = 0,
    volume = 0,
  } = currentVehicle || {};

  const maxVehicleVolume = volume * 1000 * 1000 || 0;
  const maxVehicleWeight = weight || 0;

  const validateVolume = (_: any, value: string): Promise<void> => {
    const intValue = parseInt(value);

    if (intValue < 1 || isNaN(intValue)) {
      return Promise.reject(new Error());
    }

    const fieldValues: number[] = form.getFieldsValue([
      `shipment[${id}][quantity]`,
      `shipment[${id}][length]`,
      `shipment[${id}][width]`,
      `shipment[${id}][height]`,
    ]);

    let totalVolume = 1;
    Object.values(fieldValues).forEach((vol) => {
      totalVolume *= vol;
    });

    if (totalVolume > maxVehicleVolume) {
      return Promise.reject(new Error());
    }

    return Promise.resolve();
  };

  const validateWeight = (_: any, value: string): Promise<void> => {
    const intValue = parseInt(value);

    if (intValue < 1 || isNaN(intValue)) {
      return Promise.reject(new Error(t`Please enter a value.`));
    }

    const fieldValues: number[] = form.getFieldsValue([
      `shipment[${id}][quantity]`,
      `shipment[${id}][weight]`,
    ]);

    let totalWeight = 1;
    Object.values(fieldValues).forEach((w) => {
      totalWeight *= w;
    });

    if (totalWeight > maxVehicleWeight) {
      return Promise.reject(new Error(t`Please enter a value.`));
    }

    return Promise.resolve();
  };

  return (
    <section className="shipment" id={`shipment-${id}`}>
      {currentVehicle ? (
        <>
          <Space className="shipment-add">
            <Button
              type="dashed"
              size="small"
              icon={<PlusOutlined />}
              onClick={onClickAddHandler}
            />
            {id > 0 && (
              <Button
                type="dashed"
                danger={true}
                size="small"
                icon={<DeleteOutlined />}
                onClick={() => onClickRemoveHandler(id)}
              />
            )}
          </Space>
          <Row gutter={10}>
            <Col xs={24} md={6}>
              <Form.Item
                name={`shipment[${id}][quantity]`}
                label={t`Quantity`}
                rules={[
                  {
                    required: true,
                    message: t`This field is required.`,
                  },
                ]}
                initialValue={1}
              >
                <InputNumber min={1} max={100} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={18}>
              <Form.Item
                name={`shipment[${id}][goods]`}
                label={t`Goods (what has to be sent?)`}
              >
                <Input type="" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col xs={24} md={6}>
              <Form.Item
                name={`shipment[${id}][weight]`}
                label={t`Weight (kg per item)`}
                rules={[
                  {
                    required: true,
                    message: t`Please enter a value less than or equal to ${weight}`,
                    validator: validateWeight,
                  },
                ]}
              >
                <Input type="" />
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item
                name={`shipment[${id}][length]`}
                label={t`Length (cm per item)`}
                rules={[
                  {
                    required: true,
                    message: t`Please enter a value less than or equal to ${length}`,
                    validator: validateVolume,
                  },
                ]}
              >
                <Input type="" />
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item
                name={`shipment[${id}][width]`}
                label={t`Width (cm per item)`}
                rules={[
                  {
                    required: true,
                    message: t`Please enter a value less than or equal to ${width}`,
                    validator: validateVolume,
                  },
                ]}
              >
                <Input type="" />
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item
                name={`shipment[${id}][height]`}
                label={t`Height (cm per item)`}
                rules={[
                  {
                    required: true,
                    message: t`Please enter a value less than or equal to ${height}`,
                    validator: validateVolume,
                  },
                ]}
              >
                <Input type="" />
              </Form.Item>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Skeleton />
        </>
      )}
    </section>
  );
};

export default ShipmentInformation;
