import Icon, { IconProps } from "../../icon";

const VehicleInformation = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg
        width="30"
        height="36"
        viewBox="0 0 30 36"
        xmlns="http://www.w3.org/2000/svg"
        style={{ rotate: "90deg", marginLeft: 10 }}
      >
        <defs>
          <clipPath id="PlatformIconClipPath--a">
            <path
              d="M17.338 19.548l11.693 6.626c1.292.732 1.292 1.919 0 2.65l-11.693 6.627a5.254 5.254 0 01-4.676 0L.969 28.827c-1.292-.731-1.292-1.918 0-2.65l11.693-6.627a5.255 5.255 0 014.676-.002zm0-19l11.693 6.626c1.292.732 1.292 1.919 0 2.65l-11.693 6.627a5.254 5.254 0 01-4.676 0L.969 9.827c-1.292-.731-1.292-1.918 0-2.65L12.662.55a5.255 5.255 0 014.676-.002z"
              style={{ fill: "var(--fcd-icon-background)" }}
            ></path>
          </clipPath>
        </defs>
        <path
          d="M17.338 19.548l11.693 6.626c1.292.732 1.292 1.919 0 2.65l-11.693 6.627a5.254 5.254 0 01-4.676 0L.969 28.827c-1.292-.731-1.292-1.918 0-2.65l11.693-6.627a5.255 5.255 0 014.676-.002zm0-19l11.693 6.626c1.292.732 1.292 1.919 0 2.65l-11.693 6.627a5.254 5.254 0 01-4.676 0L.969 9.827c-1.292-.731-1.292-1.918 0-2.65L12.662.55a5.255 5.255 0 014.676-.002z"
          style={{ fill: "var(--fcd-icon-background)" }}
        ></path>
        <path
          d="M17.338 10.548l11.693 6.626c1.292.732 1.292 1.919 0 2.65l-11.693 6.627a5.254 5.254 0 01-4.676 0L.969 19.827c-1.292-.731-1.292-1.918 0-2.65l11.693-6.627a5.255 5.255 0 014.676-.002z"
          style={{ fill: "var(--fcd-icon-foreground)" }}
        ></path>
        <g clipPath="url(#PlatformIconClipPath--a)">
          <path
            d="M17.338 10.548l11.693 6.626c1.292.732 1.292 1.919 0 2.65l-11.693 6.627a5.254 5.254 0 01-4.676 0L.969 19.827c-1.292-.731-1.292-1.918 0-2.65l11.693-6.627a5.255 5.255 0 014.676-.002z"
            style={{ fill: "var(--fcd-icon-intersection)" }}
          ></path>
        </g>
      </svg>
    </Icon>
  );
};

export default VehicleInformation;
