import Icon, { IconProps } from "../../icon";

const LoadingInformationIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg
        viewBox="0 0 40 36"
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="36"
      >
        <defs>
          <clipPath id="verticalArrowsIconClipPath--a">
            <path
              d="M11.65 9.52a2 2 0 0 1 2.7 0l11 10a2 2 0 1 1-2.7 2.96L15 15.52V34a2 2 0 1 1-4 0V15.52l-7.65 6.96a2 2 0 0 1-2.7-.01l-.13-.12a2 2 0 0 1 .13-2.83z"
              fill="var(--fcd-icon-background)"
            ></path>
          </clipPath>
        </defs>
        <path
          d="M11.65 9.52a2 2 0 0 1 2.7 0l11 10a2 2 0 1 1-2.7 2.96L15 15.52V34a2 2 0 1 1-4 0V15.52l-7.65 6.96a2 2 0 0 1-2.7-.01l-.13-.12a2 2 0 0 1 .13-2.83z"
          fill="var(--fcd-icon-background)"
        ></path>
        <path
          d="M27 0a2 2 0 0 1 2 2v17.23l7.65-6.96a2 2 0 1 1 2.7 2.96l-11 10a2 2 0 0 1-2.7 0l-11-10a2 2 0 0 1 2.7-2.96L25 19.23V2c0-1.1.9-2 2-2z"
          fill="var(--fcd-icon-foreground)"
        ></path>
        <g clipPath="url(#verticalArrowsIconClipPath--a)">
          <path
            d="M27 0a2 2 0 0 1 2 2v17.23l7.65-6.96a2 2 0 1 1 2.7 2.96l-11 10a2 2 0 0 1-2.7 0l-11-10a2 2 0 0 1 2.7-2.96L25 19.23V2c0-1.1.9-2 2-2z"
            fill="var(--fcd-icon-intersection)"
          ></path>
        </g>
      </svg>
    </Icon>
  );
};

export default LoadingInformationIcon;
