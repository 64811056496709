import React from "react";

import { Api, Context as ApiContext } from "../api";

const useApi = (): Api => {
  const context = React.useContext(ApiContext);

  if (!context) {
    throw new Error(
      "ApiContext is not initialized, wrap the app with ApiProvider."
    );
  }

  return context;
};

export default useApi;
