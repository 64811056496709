import React from "react";
import { t } from "ttag";
import debounce from "lodash.debounce";

import { Spin, Form, Row, Col, Button, Space, AutoComplete } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";

import { CountrySelect } from "../../select";
import { DatePicker } from "../../date-picker";

import "./styles.less";
import { setHours, setMinutes } from "date-fns";

interface LoadingInformationProps {
  id: number;
  prefix: string;
  onClickAddHandler: () => void;
  onClickRemoveHandler: (id: number, prefix: string) => void;
  onChangeHandler: () => void;
}

const LoadingInformation = ({
  id,
  prefix,
  onClickAddHandler,
  onClickRemoveHandler,
  onChangeHandler,
}: LoadingInformationProps): React.ReactElement => {
  const bookingForm = Form.useFormInstance();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [country, setCountry] = React.useState<string>("");
  const [suggestions, setSuggestions] = React.useState<
    | {
        idx: number;
        placeId: string;
        description: string;
        terms: { value: string; offset: number }[];
      }[]
    | undefined
  >([]);
  const [addressSuggestions, setAddressSuggestions] = React.useState<
    | {
        idx: number;
        placeId: string;
        description: string;
        terms: { value: string; offset: number }[];
      }[]
    | undefined
  >([]);
  const today = new Date();
  const [startDate, setStartDate] = React.useState<Date | null>(
    setHours(setMinutes(new Date(), today.getMinutes()), today.getHours())
  );
  const [endDate, setEndDate] = React.useState<Date | null>(
    setHours(setMinutes(new Date(), today.getMinutes()), today.getHours())
  );

  const googleAutocompleteService =
    new window.google.maps.places.AutocompleteService();
  const googleAutocompleteOK = window.google.maps.places.PlacesServiceStatus.OK;

  const handleCountryChange = (value: string): void => {
    setCountry(value);
    setSuggestions([]);
    setAddressSuggestions([]);
    bookingForm.setFieldValue(`${prefix}[${id}][city]`, null);
    onChangeHandler();
  };

  const autocompleteCallback = (
    predictions: google.maps.places.AutocompletePrediction[] | null,
    status: google.maps.places.PlacesServiceStatus
  ): void => {
    setLoading(false);

    if (status !== googleAutocompleteOK) {
      return;
    }

    setSuggestions(
      predictions?.map((p, i) => ({
        idx: i,
        placeId: p.place_id,
        description: p.description,
        terms: p.terms,
      }))
    );
  };

  const autocompleteAddressCallback = (
    predictions: google.maps.places.AutocompletePrediction[] | null,
    status: google.maps.places.PlacesServiceStatus
  ): void => {
    setLoading(false);

    if (status !== googleAutocompleteOK) {
      return;
    }

    setAddressSuggestions(
      predictions?.map((p, i) => ({
        idx: i,
        placeId: p.place_id,
        description: p.description,
        terms: p.terms,
      }))
    );
  };

  const fetchPredictions = debounce((value: string) => {
    const countryFieldValue = bookingForm.getFieldValue(
      `${prefix}[${id}][country]`
    );

    if (!country.length) {
      setCountry(countryFieldValue);
    }

    if (value.length && country.length) {
      setLoading(true);

      googleAutocompleteService.getPlacePredictions(
        {
          input: value,
          types: ["locality", "postal_code"],
          componentRestrictions: {
            country,
          },
        },
        autocompleteCallback
      );
    }
  }, 200);

  const fetchAddressPredictions = debounce((value: string) => {
    const countryFieldValue = bookingForm.getFieldValue(
      `${prefix}[${id}][country]`
    );

    if (!country.length) {
      setCountry(countryFieldValue);
    }

    if (value.length && country.length) {
      setLoading(true);

      googleAutocompleteService.getPlacePredictions(
        {
          input: value,
          types: ["address"],
          componentRestrictions: {
            country,
          },
        },
        autocompleteAddressCallback
      );
    }
  }, 200);

  return (
    <section className="destination" id={`section-${prefix}`}>
      <Space className="destination-add">
        <Button
          type="dashed"
          size="small"
          icon={<PlusOutlined />}
          onClick={onClickAddHandler}
        />
        {id > 0 && (
          <Button
            type="dashed"
            danger={true}
            size="small"
            icon={<DeleteOutlined />}
            onClick={() => onClickRemoveHandler(id, prefix)}
          />
        )}
      </Space>
      <Row gutter={24}>
        <Col xs={24} md={6}>
          <Form.Item
            name={`${prefix}[${id}][country]`}
            label={t`Country`}
            rules={[
              {
                required: true,
                message: t`Please select country`,
              },
            ]}
          >
            <CountrySelect
              showSearch
              onChange={handleCountryChange}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={18}>
          <Form.Item
            name={`${prefix}[${id}][city]`}
            label={t`City or post code`}
            rules={[
              {
                required: true,
                message: t`Please fill post code or city`,
              },
            ]}
          >
            <AutoComplete
              onBlur={onChangeHandler}
              onSearch={fetchPredictions}
              notFoundContent={loading ? <Spin /> : ""}
            >
              {suggestions?.map(({ idx, placeId, description }) => (
                <AutoComplete.Option
                  key={`${idx}-${placeId}`}
                  value={description}
                >
                  {description}
                </AutoComplete.Option>
              ))}
            </AutoComplete>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs="24" style={{ width: "100%" }}>
          <Form.Item name={`${prefix}[${id}][address]`} label={t`Address`}>
            <AutoComplete
              onSearch={fetchAddressPredictions}
              notFoundContent={loading ? <Spin /> : ""}
            >
              {addressSuggestions?.map(({ idx, placeId, description }) => (
                <AutoComplete.Option
                  key={`${idx}-${placeId}`}
                  value={description}
                >
                  {description}
                </AutoComplete.Option>
              ))}
            </AutoComplete>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12}>
          <Form.Item name={`${prefix}[${id}][dates][0]`} label={t`Day From`}>
            <DatePicker
              selected={startDate}
              popperPlacement="bottom-start"
              onChange={(date) => setStartDate(date)}
              dateFormat="dd.MM.yyyy, HH:mm"
              showTimeSelect={true}
              disabledKeyboardNavigation
              onFocus={(e) => e.target.blur()}
              timeFormat="HH:mm"
              timeIntervals={30}
              minDate={new Date()}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name={`${prefix}[${id}][dates][1]`} label={t`Day To`}>
            <DatePicker
              selected={endDate}
              popperPlacement="bottom-start"
              onChange={(date) => setEndDate(date)}
              dateFormat="dd.MM.yyyy, HH:mm"
              showTimeSelect={true}
              disabledKeyboardNavigation
              onFocus={(e) => e.target.blur()}
              timeFormat="HH:mm"
              timeIntervals={30}
              minDate={startDate}
            />
          </Form.Item>
        </Col>
      </Row>
    </section>
  );
};

export default LoadingInformation;
