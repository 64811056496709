import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import "antd/dist/antd.min.css";

import { Layout } from "./components/layout";
import BookingForm from "./components/booking/booking-form";
import { Confirmation } from "./components/confirmation";
import { initLocales } from "./locale/locales";

const locales = initLocales(
  require.context("./locale/messages", true, /.json$/, "lazy")
);

function App() {
  return (
    <Router basename="/">
      <Layout showHeader={true}>
        <Routes>
          <Route path="/" element={<BookingForm />} />
          <Route path="/confirmation" element={<Confirmation />} />
          <Route path="*" element={<Navigate replace={true} to="/" />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
