import React from "react";
import { useLocation } from "react-router-dom";
import { Form, Radio, RadioChangeEvent, Image, Row, Col, Card } from "antd";
import Meta from "antd/lib/card/Meta";
import { t } from "ttag";

import vehicles, { vehiclesData } from "../../../data/vehicles";
import { Vehicle } from "../../../types";
import TickIcon from "../../icon/glyphs/tick";

import "./styles.less";

interface VehicleInformationProps {
  currentVehicle?: Vehicle;
  disabled: boolean;
  onChange: (e: RadioChangeEvent) => void;
}

const VehicleInformation = ({
  currentVehicle,
  disabled,
  onChange,
}: VehicleInformationProps): JSX.Element => {
  const { id: currentId } = currentVehicle || {};

  return (
    <Form.Item name="vehicle" label={t`Vehicle`}>
      <Row className="fcd-vehicles">
        {vehicles.map(({ id, title, description, image }: Vehicle) => (
          <Col xs={24} md={8} key={`vehicle-${id}`}>
            <Radio
              value={id}
              checked={id === currentId}
              onChange={onChange}
              disabled={disabled}
            >
              <TickIcon className="tick" label="" />
              <Card
                style={{ width: "100%" }}
                cover={
                  <Image src={image} preview={false} alt={t`Image ${title}`} />
                }
              >
                <Meta
                  title={vehiclesData["bg"][id - 1].title}
                  description={vehiclesData["bg"][id - 1].description}
                />
              </Card>
            </Radio>
          </Col>
        ))}
      </Row>
    </Form.Item>
  );
};

export default VehicleInformation;
