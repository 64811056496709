import React from "react";
import classNames from "classnames";

import "./form-section-styles.less";

interface FormSectionProps {
  icon?: React.ReactNode;
  title?: React.ReactNode;
  description?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
}

const FormSection = ({
  className,
  icon,
  title,
  description,
  children,
}: FormSectionProps): React.ReactElement => {
  return (
    <section className={classNames(className, "fcd-form-section")}>
      <header className="fcd-row header">
        {icon && <div className="fcd-col icon">{icon}</div>}
        {title && <h3 className="fcd-col title">{title}</h3>}
      </header>
      {description && <p className="description">{description}</p>}
      {children}
    </section>
  );
};

export default FormSection;
