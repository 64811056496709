import React from "react";

import logoImage from "../../assets/images/logo/logo.svg";
import { LogoProps } from "./types";

const MainLogo = ({ className }: LogoProps): React.ReactElement => {
  return (
    <a className={className} href="https://mikrobus.bg/" target="_self">
      <img src={logoImage} alt="Mikrobus logo" width={200} height={35} />
    </a>
  );
};

export default MainLogo;
