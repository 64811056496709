import { Vehicle } from "../types";

import caddy from "../assets/images/vehicles/caddy.jpg";
import van from "../assets/images/vehicles/bus.jpg";
import curtain from "../assets/images/vehicles/curtain.jpg";

const vehicles: Readonly<Vehicle[]> = [
  {
    id: 1,
    order: 1,
    pallets: 1,
    length: 120,
    width: 80,
    height: 100,
    weight: 400,
    volume: 0.96,
    title: "Caddy",
    description: "1 palette (120x80x100cm, max. 400kg)",
    image: caddy,
  },
  {
    id: 2,
    order: 2,
    pallets: 6,
    length: 460,
    width: 175,
    height: 200,
    weight: 1200,
    volume: 16.1,
    title: "Van up to 3.5t",
    description: "6 paletts (460x175x200cm, max. 1200kg)",
    image: van,
  },
  {
    id: 3,
    order: 3,
    pallets: 10,
    length: 480,
    width: 220,
    height: 250,
    weight: 1000,
    volume: 26.4,
    title: "Curtain van up to 3.5t",
    description: "10 paletts (480x220x250cm, max. 1000kg)",
    image: curtain,
  },
];

export const vehiclesData: Record<string, any> = {
  bg: [
    {
      id: 1,
      title: "Caddy",
      description: "1 палет (120x80x100см, макс. 400кг)",
    },
    {
      id: 2,
      title: "Бус до 3.5t",
      description: "6 палети (460x175x200цм, макс. 1200кг)",
    },
    {
      id: 3,
      title: "Бризентов бус до 3.5t",
      description: "10 палети (480x220x250см, макс. 1000кг)",
    },
  ],
};

export default vehicles;
