import { CSSProperties, memo } from "react";
import classNames from "classnames";

import { IconProps } from "./types";

import "./styles.less";

const Icon = (props: IconProps) => {
  const {
    primaryColor = "currentColor",
    secondaryColor,
    label,
    size,
    children,
    className,
    animated = false,
    testId,
  } = props;

  const classes = classNames(
    "fcd-icon",
    {
      [`fcd-icon-${size}`]: size,
      [`fcd-icon-samecolor`]: primaryColor === secondaryColor,
      [`fcd-icon-transparent`]: secondaryColor === "transparent",
    },
    className
  );

  return (
    <span
      className={classes}
      role={label ? "img" : "presentation"}
      aria-label={label ? label : undefined}
      aria-hidden={label ? undefined : true}
      style={
        {
          "--icon-primary-color": primaryColor,
          "--icon-secondary-color": secondaryColor || "transparent",
        } as CSSProperties
      }
      data-testid={testId}
    >
      {animated ? (
        <span className={classNames("fcd-icon-container")}>{children}</span>
      ) : (
        children
      )}
    </span>
  );
};

export default memo(Icon);
