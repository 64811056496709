import { LocaleData } from "ttag";

import { TTagLocaleMap } from "./types";

export let locales: TTagLocaleMap = {};

export const getLocalesFromRequireContext = (locales: {
  keys: () => string[];
  (id: string): Promise<LocaleData>;
}) => {
  return locales
    .keys()
    .reduce<TTagLocaleMap>((acc: TTagLocaleMap, key: string) => {
      acc[key.slice(2, key.length - 5)] = () => locales(key);
      return acc;
    }, {});
};

export const setLocales = (loc: TTagLocaleMap): void => {
  locales = loc;
};

export const initLocales = (locales: {
  keys: () => string[];
  (id: string): Promise<LocaleData>;
}): TTagLocaleMap => {
  const contextLocales = getLocalesFromRequireContext(locales);
  setLocales(contextLocales);

  return contextLocales;
};
