import { Select, SelectProps } from "antd";

import { allCountries } from "./data/countries";

const PhoneCodeSelect = (props: SelectProps) => {
  return (
    <Select {...props}>
      {allCountries.map(({ code, abbr, name }) => (
        <Select.Option key={`phone-code-${abbr}`} value={code}>
          +{code}
        </Select.Option>
      ))}
    </Select>
  );
};

export default PhoneCodeSelect;
