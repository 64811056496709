export class ApiError extends Error {
  status: number;
  response?: Response;
  config?: RequestInit;
  data?: { [key: string]: any };

  constructor(message: string, status: number, name: string) {
    super(message);

    this.status = status;
    this.name = name;
  }
}

export const createApiError = (
  name: string,
  response: Response,
  config: RequestInit,
  data?: { [key: string]: any }
) => {
  const { status, statusText } = response;

  const error = new ApiError(statusText, status, name);
  error.response = response;
  error.data = data;
  error.config = config;

  return error;
};

export const createTimeoutError = (config: RequestInit): ApiError => {
  const error = new ApiError('Request timed out', -1, 'TimeoutError');
  error.config = config;
  return error;
};

export const createOfflineError = (config: RequestInit): ApiError => {
  const error = new ApiError('No network connection', 0, 'OfflineError');
  error.config = config;
  return error;
};
