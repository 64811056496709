export const DEFAULT_LANGUAGE = "de";
export const DEFAULT_TIMEOUT = 30000;

export const MENU_ITEMS = [
  {
    key: "about-us",
    label: "About us",
    to: "https://fcd-express.com/about-us",
  },
  {
    key: "services",
    label: "Services",
    to: "https://fcd-express.com/services",
  },
  {
    key: "industries",
    label: "Industries",
    to: "https://fcd-express.com/industries",
  },
  {
    key: "fleet",
    label: "Fleet",
    to: "https://fcd-express.com/fleet",
  },
  {
    key: "contact",
    label: "Contact",
    to: "https://fcd-express.com/contact",
  },
];
