import Icon, { IconProps } from "../../icon";

const ShipmentInformationIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg
        width="96"
        height="96"
        preserveAspectRatio="xMinYMin"
        viewBox="0 0 96 96"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <path
            id="blocksIconBack"
            d="M30.68 31.37l9 4.15A4 4 0 0 1 42 39.15v10.89a4 4 0 0 1-2.32 3.63l-9 4.15a4 4 0 0 1-3.36 0l-9-4.15A4 4 0 0 1 16 50.04V39.15a4 4 0 0 1 .35-1.62L29 43.36v-1.54l-11.85-5.47a4 4 0 0 1 1.17-.83l9-4.15a4 4 0 0 1 3.36 0z"
          ></path>
          <path
            id="blocksIconFront"
            d="M55 45.15v10.89a4 4 0 0 1-2.32 3.63l-9 4.15a4 4 0 0 1-3.36 0l-9-4.15A4 4 0 0 1 29 56.04V45.15a4 4 0 0 1 .35-1.62L42 49.37l12.65-5.84a4 4 0 0 1 .35 1.62zm-11.32-7.78l9 4.15a4 4 0 0 1 1.17.83L42 47.82l-11.86-5.47a4 4 0 0 1 1.18-.83l9-4.15a4 4 0 0 1 3.36 0z"
          ></path>
          <clipPath id="blocksIconClipPath">
            <use href="#blocksIconFront" transform="translate(-13, -6)"></use>
          </clipPath>
        </defs>

        <use href="#blocksIconFront" fill="var(--fcd-icon-background)"></use>
        <use href="#blocksIconFront" fill="var(--fcd-icon-background)"></use>
        <use
          href="#blocksIconFront"
          fill="var(--fcd-icon-background)"
          transform="translate(-13, -6)"
        ></use>
        <use href="#blocksIconFront" fill="var(--fcd-icon-background)"></use>
        <g clipPath="url(#blocksIconClipPath)">
          <use
            href="#blocksIconFront"
            fill="var(--fcd-icon-intersection)"
          ></use>
          <use
            href="#blocksIconFront"
            fill="var(--fcd-icon-intersection)"
          ></use>
        </g>
      </svg>
    </Icon>
  );
};

export default ShipmentInformationIcon;
