import React from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  Modal,
  RadioChangeEvent,
  Row,
  Alert,
  Input,
  AutoComplete,
  Spin,
} from "antd";
import { t } from "ttag";
import { Buffer } from "buffer";
import { isBefore, parse, format } from "date-fns";
import debounce from "lodash.debounce";

import { Vehicle } from "../../types";
import vehicles from "../../data/vehicles";
import { FormSection } from "../form";
import useApi from "../../hooks/useApi";

import PersonalInformationIcon from "../icon/glyphs/personal-information";
import LoadingInformationIcon from "../icon/glyphs/destination-information";
import VehicleInformationIcon from "../icon/glyphs/vehicle-information";
import ShipmentInformationIcon from "../icon/glyphs/shipment-information";
import PriceInformationIcon from "../icon/glyphs/price-information";

import { PersonalInformation } from "./personal-information";
import { DestinationInformation } from "./destination-information";
import { VehicleInformation } from "./vehicle-information";
import { ShipmentInformation } from "./shipment-information";

import "./styles.less";

const BookingForm = (): React.ReactElement => {
  const [bookingForm] = Form.useForm();

  const api = useApi();
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const localeCode = pathname.substring(1, 3).toLowerCase();

  const [params] = useSearchParams();
  const places = params.get("places");
  let storedPlaces: string[] = [];

  if (places) {
    const decoded = Buffer.from(places, "base64").toString("ascii");
    storedPlaces = decoded.split("|");
  }

  const [loading, setLoading] = React.useState<boolean>(false);
  const [vehicle, setVehicle] = React.useState<Vehicle>();
  const [priceInformation, setPriceInformation] = React.useState<{
    distance: number;
    duration: string;
    price: number;
  } | null>(null);

  const loadingIdsRef = React.useRef<number>(0);
  const unloadingIdsRef = React.useRef<number>(0);
  const shipmentIdsRef = React.useRef<number>(0);

  const [loadings, setLoadings] = React.useState<
    { id: number; prefix: string }[]
  >([{ id: loadingIdsRef.current, prefix: "loading" }]);

  const [unloadings, setUnloadings] = React.useState<
    { id: number; prefix: string }[]
  >([{ id: unloadingIdsRef.current, prefix: "unloading" }]);

  const [shipments, setShipments] = React.useState<{ id: number }[]>([
    { id: shipmentIdsRef.current },
  ]);

  const [showMegaForm, setShowMegaForm] = React.useState(false);

  const loadingAddHandler = (): void => {
    loadingIdsRef.current++;

    setLoadings([
      ...loadings,
      {
        id: loadingIdsRef.current,
        prefix: "loading",
      },
    ]);
  };

  const loadingRemoveHandler = (id: number, prefix: string): void => {
    setLoadings(loadings.filter((loading) => loading.id !== id));
  };

  const unloadingAddHandler = (): void => {
    unloadingIdsRef.current++;
    setUnloadings([
      ...unloadings,
      { id: unloadingIdsRef.current, prefix: "unloading" },
    ]);
  };

  const unloadingRemoveHandler = (id: number, prefix: string): void => {
    setUnloadings(unloadings.filter((unloading) => unloading.id !== id));
  };

  const shipmentAddHandler = (): void => {
    shipmentIdsRef.current++;
    setShipments([...shipments, { id: shipmentIdsRef.current }]);
  };

  const shipmentRemoveHandler = (id: number): void => {
    setShipments(shipments.filter((shipment) => shipment.id !== id));
  };

  const onVehicleChange = (event: RadioChangeEvent): void => {
    const selectedValue = event.target.value;

    const selectedVehicle = vehicles.find(
      (v: Vehicle) => v.id === selectedValue
    );

    if (selectedVehicle) {
      setVehicle(selectedVehicle);
    }
  };

  const onFinishForm = (values: any): void => {
    let loadingDestinations: {
      city: string;
      country: string;
      address: string;
      dates: string[];
    }[] = [];
    let unloadingDestinations: {
      city: string;
      country: string;
      address: string;
      dates: string[];
    }[] = [];
    let shipmentItems: {
      goods?: string;
      height: number;
      length: number;
      quantity: number;
      weight: number;
      width: number;
    }[] = [];

    loadings.forEach(({ id }) => {
      loadingDestinations.push({
        city: values[`loading[${id}][city]`],
        country: values[`loading[${id}][country]`],
        address: values[`loading[${id}][address]`],
        dates: [
          values[`loading[${id}][dates][0]`]
            ? format(
                Date.parse(values[`loading[${id}][dates][0]`]),
                "dd.MM.yyyy, HH:mm"
              )
            : format(new Date(), "dd.MM.yyyy, HH:mm"),
          values[`loading[${id}][dates][1]`]
            ? format(
                Date.parse(values[`loading[${id}][dates][1]`]),
                "dd.MM.yyyy, HH:mm"
              )
            : format(new Date(), "dd.MM.yyyy, HH:mm"),
        ],
      });
    });

    unloadings.forEach(({ id }) => {
      unloadingDestinations.push({
        city: values[`unloading[${id}][city]`],
        country: values[`unloading[${id}][country]`],
        address: values[`unloading[${id}][address]`],
        dates: [
          values[`unloading[${id}][dates][0]`]
            ? format(
                Date.parse(values[`unloading[${id}][dates][0]`]),
                "dd.MM.yyyy, HH:mm"
              )
            : format(new Date(), "dd.MM.yyyy, HH:mm"),
          values[`unloading[${id}][dates][1]`]
            ? format(
                Date.parse(values[`unloading[${id}][dates][1]`]),
                "dd.MM.yyyy, HH:mm"
              )
            : format(new Date(), "dd.MM.yyyy, HH:mm"),
        ],
      });
    });

    let totalVolume = 0;
    let totalWeight = 0;

    shipments.forEach(({ id }) => {
      const goods = `${values[`shipment[${id}][goods]`]}`;
      const height = values[`shipment[${id}][height]`];
      const length = values[`shipment[${id}][length]`];
      const quantity = values[`shipment[${id}][quantity]`];
      const weight = values[`shipment[${id}][weight]`];
      const width = values[`shipment[${id}][width]`];

      shipmentItems.push({
        goods,
        height,
        length,
        quantity,
        weight,
        width,
      });

      totalVolume += quantity * height * width * length;
      totalWeight += quantity * weight;
    });

    if (loadingDestinations[0]) {
      const dateLoading = parse(
        loadingDestinations[0]["dates"][0],
        "dd.MM.yyyy, HH:mm",
        new Date()
      );
      const dateUnloading = parse(
        unloadingDestinations[0]["dates"][0],
        "dd.MM.yyyy, HH:mm",
        new Date()
      );
      const isValid = isBefore(dateLoading, dateUnloading);

      if (!isValid) {
        Modal.error({
          title: t`Error`,
          content: t`Date of unloading is before date of loading.`,
          onOk: () => {
            bookingForm.scrollToField("loading[0][dates][0]");
          },
        });
        return;
      }
    }

    const maxVehicleVolume = vehicle?.volume || 0;
    const maxVehicleWeight = vehicle?.weight || 0;

    if (totalVolume / 1000 / 1000 > maxVehicleVolume) {
      Modal.error({
        title: t`Load is too big for this vehicle`,
        content: `The total volume can't be over ${maxVehicleVolume}㎥`,

        okText: t`Continue with bigger vehicle`,
        onOk: () => {
          const currentVehicle = vehicle?.id || 1;
          const nextVehicle = vehicles.find(
            ({ id }) => id === currentVehicle + 1
          );

          if (nextVehicle) {
            setVehicle(nextVehicle);
          }
        },
      });
      return;
    } else if (totalWeight > maxVehicleWeight) {
      Modal.error({
        title: t`Load is too heavy for this vehicle`,
        content: `The total weight can't be over ${maxVehicleWeight}`,
        okText: t`Continue with bigger vehicle`,
        onOk: () => {
          const currentVehicle = vehicle?.id || 1;
          const nextVehicle = vehicles.find(
            ({ id }) => id === currentVehicle + 1
          );

          if (nextVehicle) {
            setVehicle(nextVehicle);
          }
        },
      });
      return;
    }

    setLoading(true);

    const data = {
      customer: {
        names: values["names"],
        company: values["company"],
        email: values["email"],
        phone: `(${values["phonePrefix"]})-${values["phone"]}`,
        notes: values["notes"],
        ...priceInformation,
      },
      vehicle: vehicle?.id,
      loadings: loadingDestinations,
      unloadings: unloadingDestinations,
      shipments: shipmentItems,
    };

    api({
      url: "booking",
      method: "POST",
      data,
      params: {},
      headers: {
        "Accept-Language": `${localeCode}`,
        "x-fcd-application": "mikrobus",
      },
    })
      .then((response: any) => {
        if (response.success) {
          navigate(`/${localeCode}/confirmation`);
        }

        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    sendPriceRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicle]);

  const sendPriceRequest = () => {
    if (!vehicle) {
      return;
    }

    const values = bookingForm.getFieldsValue();

    if (!values["loading[0][city]"] || !values["unloading[0][city]"]) {
      return;
    }

    let loadingDestinations: { city: string; country: string }[] = [];
    let unloadingDestinations: { city: string; country: string }[] = [];

    loadings.forEach(({ id }) => {
      loadingDestinations.push({
        city: values[`loading[${id}][city]`],
        country: values[`loading[${id}][country]`],
      });
    });

    unloadings.forEach(({ id }) => {
      unloadingDestinations.push({
        city: values[`unloading[${id}][city]`],
        country: values[`unloading[${id}][country]`],
      });
    });

    bookingForm.validateFields([
      "loading[0][country]",
      "loading[0][city]",
      "unloading[0][country]",
      "unloading[0][city]",
      "vehicle",
    ]);

    if (!loadingDestinations[0]["country"] || !loadingDestinations[0]["city"]) {
      return;
    }

    if (
      !unloadingDestinations[0]["country"] ||
      !unloadingDestinations[0]["city"]
    ) {
      return;
    }

    setLoading(true);

    api({
      url: "booking/prices",
      method: "POST",
      data: {
        vehicle: vehicle?.id || 1,
        loadings: loadingDestinations,
        unloadings: unloadingDestinations,
      },
      params: {},
    })
      .then((response: any) => {
        if (response.success) {
          setPriceInformation({
            distance: Math.round(response.distance / 1000),
            duration: new Date(response.duration * 1000)
              .toISOString()
              .substring(11, 16),
            price: response.price,
          });
        }

        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const sendSinglePriceRequest = () => {
    const values = bookingForm.getFieldsValue();
    console.log(values);
    if (vehicle) {
      setShowMegaForm(true);
      sendPriceRequest();
    }
  };

  React.useEffect(() => {
    let updateDestinations = true;
    if (storedPlaces && storedPlaces.length > 0) {
      bookingForm.setFieldValue("loading[0][city]", storedPlaces[1]);
      bookingForm.setFieldValue("loading[0][country]", storedPlaces[2]);
      bookingForm.setFieldValue("unloading[0][city]", storedPlaces[3]);
      bookingForm.setFieldValue("unloading[0][country]", storedPlaces[4]);

      const storedVehicle = vehicles.find(
        (v) => v.id === parseInt(storedPlaces[0])
      );

      if (storedVehicle) {
        setVehicle(storedVehicle);
      }

      updateDestinations = false;
    }

    api({
      url: "ip-lookup/country",
      method: "GET",
      data: [],
      params: {},
    })
      .then((response: any) => {
        if (response.success) {
          bookingForm.setFieldValue("phonePrefix", response.data["dialing"]);

          if (updateDestinations) {
            bookingForm.setFieldValue(
              "loading[0][country]",
              response.data["country"]
            );
            bookingForm.setFieldValue(
              "unloading[0][country]",
              response.data["country"]
            );
          }
        }
      })
      .catch((e) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDestinationChange = () => {
    sendPriceRequest();
  };

  const [suggestions, setSuggestions] = React.useState<
    | {
        idx: number;
        placeId: string;
        description: string;
        terms: { value: string; offset: number }[];
      }[]
    | undefined
  >([]);

  const mapDiv = React.useRef<HTMLDivElement>(null);
  const googleAutocompleteService =
    new window.google.maps.places.AutocompleteService();
  const googleAutocompleteOK = window.google.maps.places.PlacesServiceStatus.OK;
  const googlePlacesService = new window.google.maps.places.PlacesService(
    new window.google.maps.Map(document.createElement("div"))
  );

  const autocompleteCallback = (
    predictions: google.maps.places.AutocompletePrediction[] | null,
    status: google.maps.places.PlacesServiceStatus
  ): void => {
    setLoading(false);

    if (status !== googleAutocompleteOK) {
      return;
    }

    setSuggestions(
      predictions?.map((p, i) => ({
        idx: i,
        placeId: p.place_id,
        description: p.description,
        terms: p.terms,
      }))
    );
  };

  const fetchPredictions = debounce((value: string) => {
    if (value.length) {
      googleAutocompleteService.getPlacePredictions(
        {
          input: value,
          types: ["locality", "postal_code"],
          // componentRestrictions: {},
        },
        autocompleteCallback
      );
    }
  }, 200);

  const fetchPlaceDetails = (name: string, type: string): void => {
    const place = suggestions?.find(({ description }) => description === name);

    googlePlacesService.getDetails(
      { placeId: place?.placeId!, fields: ["address_components"] },
      (result, status) => {
        if (
          result !== null &&
          status === google.maps.places.PlacesServiceStatus.OK
        ) {
          let countryCode = "";
          result.address_components?.forEach(({ types, short_name }) => {
            if (types.includes("country")) {
              countryCode = short_name;
            }
          });

          bookingForm.setFieldValue(`${type}[0][country]`, countryCode);
          bookingForm.setFieldValue(`${type}[0][city]`, name);
        }
      }
    );
  };

  return (
    <Form
      className="booking"
      form={bookingForm}
      name="booking"
      layout="vertical"
      autoComplete="off"
      onFinish={onFinishForm}
    >
      <FormSection
        title={t`Vehicle Information`}
        icon={<VehicleInformationIcon label="Vehicle Information" />}
      >
        <VehicleInformation
          onChange={onVehicleChange}
          currentVehicle={vehicle}
          disabled={loading}
        />
        <Row gutter={24}>
          <Col xs={24} md={12} style={{ width: "100%" }}>
            <Form.Item name={`fromAddress`} label={t`Loading city`}>
              <AutoComplete
                onSearch={fetchPredictions}
                notFoundContent={loading ? <Spin /> : ""}
                style={{ width: "100%" }}
                onSelect={(v: string) => {
                  fetchPlaceDetails(v, "loading");
                }}
              >
                {suggestions?.map(({ idx, placeId, description }) => (
                  <AutoComplete.Option
                    key={`${idx}-${placeId}`}
                    value={description}
                  >
                    {description}
                  </AutoComplete.Option>
                ))}
              </AutoComplete>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item name={`toAddress`} label={t`Unloading city`}>
              <AutoComplete
                onSearch={fetchPredictions}
                notFoundContent={loading ? <Spin /> : ""}
                style={{ width: "100%" }}
                onSelect={(v: string) => {
                  fetchPlaceDetails(v, "unloading");
                }}
              >
                {suggestions?.map(({ idx, placeId, description }) => (
                  <AutoComplete.Option
                    key={`${idx}-${placeId}`}
                    value={description}
                    style={{ width: "100%" }}
                  >
                    {description}
                  </AutoComplete.Option>
                ))}
              </AutoComplete>
            </Form.Item>
          </Col>
        </Row>
        {priceInformation && (
          <Row>
            <Col xs={24} md={24} className="fcd-col-price">
              <div className="fcd-booking-label big">
                {priceInformation?.price! > 0 ? (
                  <span>
                    {t`Price:`} {`${priceInformation?.price} EUR`}
                  </span>
                ) : (
                  <span>{t`Send Price Request`}</span>
                )}
              </div>
            </Col>
          </Row>
        )}

        <div ref={mapDiv}></div>
      </FormSection>

      <div style={{ display: showMegaForm ? "block" : "none" }}>
        <FormSection
          title={t`Loading Information`}
          icon={<LoadingInformationIcon label="" />}
        >
          {loadings.map(({ id, prefix }) => (
            <DestinationInformation
              key={`${prefix}-${id}`}
              id={id}
              prefix={prefix}
              onClickAddHandler={loadingAddHandler}
              onClickRemoveHandler={loadingRemoveHandler}
              onChangeHandler={onDestinationChange}
            />
          ))}
        </FormSection>
        <FormSection
          title={t`Unloading Information`}
          icon={<LoadingInformationIcon label="" />}
        >
          {unloadings.map(({ id, prefix }) => (
            <DestinationInformation
              key={`${prefix}-${id}`}
              id={id}
              prefix={prefix}
              onClickAddHandler={unloadingAddHandler}
              onClickRemoveHandler={unloadingRemoveHandler}
              onChangeHandler={onDestinationChange}
            />
          ))}
        </FormSection>
        <FormSection
          title={t`Personal Information`}
          icon={<PersonalInformationIcon label="" />}
        >
          <PersonalInformation />
        </FormSection>
      </div>
      {priceInformation ? (
        <>
          <FormSection
            title={t`Shipment Information`}
            icon={<ShipmentInformationIcon label="" animated={true} />}
          >
            {shipments.map(({ id }) => (
              <ShipmentInformation
                key={`shipment-${id}`}
                id={id}
                currentVehicle={vehicle}
                onClickAddHandler={shipmentAddHandler}
                onClickRemoveHandler={shipmentRemoveHandler}
              />
            ))}
            <Form.Item name={"notes"} label={t`Notes`}>
              <Input.TextArea rows={4} />
            </Form.Item>
            <Alert
              message=""
              description={t`Please note that the prices are for a full van. For a group shipment, please send us the request, and we wil get in touch with you for the best price solution!`}
              showIcon={true}
            />
          </FormSection>
          <FormSection
            title={t`Price Information`}
            icon={<PriceInformationIcon label="" animated={true} />}
          >
            <Row>
              <Col xs={24} md={24} className="fcd-col-price">
                <div className="fcd-booking-label">{t`Book now for`}</div>
                <div className="fcd-booking-price">
                  {priceInformation.price > 0 ? (
                    <Button
                      loading={loading}
                      htmlType="submit"
                    >{`${priceInformation.price} EUR`}</Button>
                  ) : (
                    <Button
                      loading={loading}
                      htmlType="submit"
                    >{t`Send Price Request`}</Button>
                  )}
                </div>
                <div className="fcd-booking-driving-time">
                  {t`Estimated driving time ${priceInformation.duration} hours for ${priceInformation.distance} km`}
                </div>
              </Col>
            </Row>
          </FormSection>
        </>
      ) : showMegaForm ? (
        <Row>
          <Col span={24} style={{ textAlign: "right" }}>
            <Button
              type="primary"
              htmlType="button"
              loading={loading}
              className="fcd-booking-submit"
              onClick={sendPriceRequest}
            >
              {t`Request for price`}
            </Button>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col span={24} style={{ textAlign: "right" }}>
            <Button
              type="primary"
              htmlType="button"
              loading={loading}
              className="fcd-booking-submit"
              onClick={sendSinglePriceRequest}
              disabled={!vehicle}
            >
              {t`Request for price`}
            </Button>
          </Col>
        </Row>
      )}
    </Form>
  );
};

export default BookingForm;
