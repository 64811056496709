import React from "react";

import "./styles.less";

const Guides = (): React.ReactElement => {
  return (
    <section className="fcd-guides">
      <div className="container">
        <div className="guide"></div>
        <div className="guide"></div>
      </div>
    </section>
  );
};

export default React.memo(Guides);
