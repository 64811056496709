import React from "react";
import { Layout } from "antd";
import classNames from "classnames";

interface FooterProps {
  className?: string;
}

const Footer = ({ className }: FooterProps): React.ReactElement => {
  return (
    <Layout.Footer className={classNames(className)}>footer</Layout.Footer>
  );
};

export default React.memo(Footer);
