import { createApiError } from "./errors";
import {
  FetchInputData,
  FetchInputType,
  QueryParams,
  SerializedData,
} from "./types";

const appendQueryParams = (url: URL, params: QueryParams): void => {
  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (typeof value !== "undefined") {
      url.searchParams.append(key, value);
    }
  });
};

export const createEndpoint = (url: string, params: QueryParams = {}): URL => {
  const endpoint = new URL(url, window.location.origin);
  appendQueryParams(endpoint, params);
  return endpoint;
};

export const checkResponseStatus = (
  response: Response,
  config: RequestInit
) => {
  const { status } = response;

  if (status >= 200 && status < 300) {
    return response;
  }

  return response
    .json()
    .catch(() => {
      return {};
    })
    .then((data) => {
      throw createApiError("StatusCodeError", response, config, data);
    });
};

export const serializeFormData = (data: FetchInputData): FormData => {
  const formData = new FormData();

  Object.keys(data).forEach((key: string) => {
    const value = data[key];
    if (Array.isArray(value)) {
      value.forEach((val: any) => formData.append(key, val));
    } else {
      formData.append(key, value);
    }
  });

  return formData;
};

export const serializeData = (
  data: FetchInputData,
  input: FetchInputType
): SerializedData => {
  if (!data) {
    return {};
  }

  if (input === "json") {
    let result: SerializedData = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (Object.entries(data).length > 0) {
      result["body"] = JSON.stringify(data);
    }

    return result;
  }

  if (input === "form") {
    return {
      body: serializeFormData(data),
    };
  }

  return {};
};
