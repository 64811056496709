import React from "react";
import { Select, SelectProps } from "antd";

import { allCountries } from "./data/countries";

export type Country = typeof allCountries[number];

const getOptionLabel = ({
  abbr,
  name,
}: Omit<Country, "icon" | "code">): string => {
  return `${abbr.toUpperCase()} ${name}`;
};

const CountrySelect = (props: SelectProps) => {
  return (
    <Select
      {...props}
      optionFilterProp="children"
      filterOption={(input, option) => {
        const label = React.Children.toArray(
          option!.children as any
        )[1] as string;
        return label.toLowerCase().includes(input.toLocaleLowerCase());
      }}
      filterSort={(optionA, optionB) => {
        const textA = React.Children.toArray(
          optionA.children as any
        )[1] as string;
        const textB = React.Children.toArray(
          optionB.children as any
        )[1] as string;
        return textA.toLowerCase().localeCompare(textB.toLowerCase());
      }}
    >
      {allCountries.map(({ code, abbr, name, icon }) => (
        <Select.Option key={`country-${abbr}`} value={abbr}>
          <span style={{ fontSize: 18, marginRight: 8 }}>{icon}</span>
          {getOptionLabel({ abbr, name })}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CountrySelect;
